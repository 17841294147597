export interface InitialState {
	dispatch?: any;
	activeRouteId: undefined | boolean | string;
	currentAppId: undefined | number | string;
	currentChildren: any;
	filteredChildren: [];
	isNavExpanded: boolean;
	isFlyoutPinned: boolean;
	isFlyoutOpen: boolean;
	focusTrap: boolean;
	inactiveStyles: boolean;
	isSearchActive: boolean;
	search: string;
	clickedFavoritedItem?: any;
	favoriteMenuStore: [];
	recentSearches: [];
}

export const initialState: InitialState = {
	activeRouteId: undefined,
	currentAppId: undefined,
	currentChildren: [],
	filteredChildren: [],
	isNavExpanded: true,
	isFlyoutPinned: false,
	isFlyoutOpen: false,
	focusTrap: false,
	inactiveStyles: false,
	isSearchActive: false,
	search: '',
	clickedFavoritedItem: undefined,
	favoriteMenuStore: [],
	recentSearches: []
};
