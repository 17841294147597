import { adminNavIds } from '@flexera/admin.bootstrap';
import { appBrokerNavIds } from '@flexera/appBroker.bootstrap';
import { cmNavIds } from '@flexera/cloud-management.bootstrap';
import { itvNavIds } from '@flexera/itv.bootstrap';
import { optimaNavIds } from '@flexera/optima.bootstrap';
import { saasNavIds } from '@flexera/saas.bootstrap';
import { settingsNavIds } from '@flexera/settings.bootstrap';
import { NavOverrides, NavProvider } from '@flexera/shell.navigation';
import { udiNavIds } from '@flexera/udi.bootstrap';
import { selfServiceNavIds } from '@flexera/self-service.bootstrap';
import {
	IconFxAppCatalog,
	IconFxConnector,
	IconFxCalculator,
	IconMdAddUser,
	IconMdCloud,
	IconMdCloudDownload,
	IconMdDashboard,
	IconMdEmail,
	IconMdLineGraph,
	IconMdPayment,
	IconMdRequests,
	IconMdSettings,
	IconMdView
} from '@flexera/ui.component-library';
import { spendNavIds } from '@flexera/spend.bootstrap';
import React, { FC } from 'react';
import { MenuIds } from './MenuIds';
import { SetupNavMenu } from './SetupNavMenu';

// TODO: Can this file be deleted? we are on v2 now

const esc = (s: string) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
const c = (s: string) => RegExp(`${esc(s)}.+`);

const overrides = new NavOverrides([
	// Admin
	[
		adminNavIds.INVITATIONS,
		{ icon: IconMdEmail, parentId: MenuIds.Administration }
	],
	[adminNavIds.IDPS, { icon: IconMdAddUser, parentId: MenuIds.Administration }],
	// App Broker
	[
		appBrokerNavIds.CATALOG,
		{ parentId: MenuIds.AppBroker, icon: IconFxAppCatalog }
	],
	[
		appBrokerNavIds.REQUESTS,
		{ parentId: MenuIds.AppBroker, icon: IconMdRequests }
	],
	[
		appBrokerNavIds.DASHBOARD,
		{
			parentId: MenuIds.PortalManagement,
			label: 'Dashboard'
		}
	],
	[c(appBrokerNavIds.PORTAL_MANAGEMENT), { parentId: MenuIds.PortalManagement }],

	// SaaS
	[
		saasNavIds.DASHBOARD,
		{ parentId: MenuIds.SaaS, icon: IconMdDashboard, label: 'Dashboard' }
	],
	[
		saasNavIds.MANAGED,
		{ parentId: MenuIds.SaaSApps, label: 'Managed', priority: 0 }
	],
	[saasNavIds.LICENSES, { parentId: MenuIds.SaaSApps, label: 'Licenses' }],
	[saasNavIds.USAGE, { parentId: MenuIds.SaaSApps, label: 'Usage' }],
	[
		saasNavIds.UNSANCTIONED,
		{ parentId: MenuIds.SaaSApps, label: 'Unsanctioned' }
	],
	[saasNavIds.ALL_USERS, { parentId: MenuIds.SaaSUsers, label: 'All Users' }],
	[
		saasNavIds.UNDERUTILIZED_USERS,
		{ parentId: MenuIds.SaaSUsers, label: 'Underutilized Accounts' }
	],
	[
		saasNavIds.SUSPICIOUS_USERS,
		{ parentId: MenuIds.SaaSUsers, label: 'Suspicious Activities' }
	],
	[
		saasNavIds.ORGANIZATION_USAGE,
		{ parentId: MenuIds.SaaSUsers, label: 'Organization Usage' }
	],
	[saasNavIds.GENERAL_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.ORG_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.AUDIT_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.APP_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.CUSTOMER_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.FINANCIAL_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.SSO_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	[saasNavIds.VENDORS_SETTINGS, { parentId: MenuIds.SaaSSettings }],
	// Cloud Management
	[c(cmNavIds.BASE), { parentId: MenuIds.CloudResourceBrowser }],
	// IT viz
	[
		itvNavIds.DASHBOARD,
		{ parentId: MenuIds.ITVisibility, icon: IconMdDashboard, label: 'Dashboard' }
	],
	[
		itvNavIds.EXPORTS,
		{
			parentId: MenuIds.ITVisibility,
			icon: IconMdCloudDownload,
			label: 'Exports'
		}
	],
	[
		itvNavIds.CONNECTIONS,
		{ parentId: MenuIds.ITVisibility, icon: IconFxConnector }
	],
	[itvNavIds.EXPLORER, { parentId: MenuIds.ITVisibility, icon: IconMdView }],
	[
		itvNavIds.TRANSPARENCY,
		{ parentId: MenuIds.ITVisibility, icon: IconFxCalculator }
	],

	[c(itvNavIds.BASE), { parentId: MenuIds.ITVisibility }],
	// UDI
	[c(udiNavIds.BASE), { parentId: MenuIds.UDI }],
	// Optima
	[
		optimaNavIds.DASHBOARD,
		{
			parentId: MenuIds.Optima,
			icon: IconMdDashboard,
			label: 'Dashboard',
			priority: 1
		}
	],
	[
		optimaNavIds.BILLING,
		{ parentId: MenuIds.Optima, icon: IconMdPayment, priority: 2 }
	],
	[
		optimaNavIds.RIS,
		{ parentId: MenuIds.Optima, icon: IconMdCloud, priority: 3 }
	],
	[
		optimaNavIds.ADJUSTMENTS,
		{ parentId: MenuIds.Optima, icon: IconMdLineGraph, priority: 4 }
	],
	[
		optimaNavIds.SETTINGS,
		{ parentId: MenuIds.Optima, icon: IconMdSettings, priority: 5 }
	],
	[
		optimaNavIds.CUSTOM_TAGS,
		{ parentId: MenuIds.Optima, icon: IconMdSettings, priority: 6 }
	],
	// Unified Spend
	[
		c(spendNavIds.BASE),
		{ parentId: MenuIds.UnifiedSpend, icon: IconMdDashboard, label: 'Dashboard' }
	],
	// Settings
	[c(settingsNavIds.BASE), { parentId: MenuIds.Settings }],
	[selfServiceNavIds.EXTERNAL, { parentId: null, priority: 500 }]
]);

export const Nav: FC = ({ children }) => {
	return (
		<NavProvider overrides={overrides}>
			<SetupNavMenu />
			{children}
		</NavProvider>
	);
};
