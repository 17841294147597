import { useAuthenticated } from '@flexera/auth.base';
import { useContent } from '@flexera/lib.content';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useNavItems } from '@flexera/shell.navigation';
import { useOrgId } from '@flexera/shell.orgs';
import {
	IconFxBeacon,
	IconMdApps,
	IconMdSettings,
	IconMdUser
} from '@flexera/ui.component-library';
import { FC, useEffect } from 'react';
import { MenuIds } from './MenuIds';

export const SetupNavMenu: FC = () => {
	const authenticated = useAuthenticated();
	const { addItems } = useNavItems();
	const orgId = useOrgId();
	const content = useContent();

	useEffect(() => {
		if (!authenticated) return;

		if (!orgId) {
			// Base nav item for settings. Not actually rendered in the nav because
			// it's the only base item that's not org scoped, but it does show the page title
			// in the header.
			return addItems([
				{
					label: 'Settings',
					description: 'Manage your user invitations.',
					pageTitle: 'Settings',
					urlMatch: /^\/settings(\/|$|\?)/,
					id: MenuIds.Settings
				}
			]);
		}

		// The capabilities below are not registered when there is no org id in the URL
		// because they are all org scoped. The navigation is function of the URL.
		return addItems([
			{
				label: 'Administration',
				description: 'Manage Flexera platform for your organization.',
				helpHref: `${helpDocBaseUrl}/Administration/AdminGettingStarted.htm`,
				pageTitle: 'Administration',
				urlMatch: /^\/orgs\/\d+\/admin(\/|$|\?)/,
				id: MenuIds.Administration
			},
			{
				label: content.appBroker,
				description: 'Automation for enterprise wide governance and self-service.',
				pageTitle: content.appBroker,
				urlMatch: /^\/orgs\/\d+\/appBroker(\/|$|\?)/,
				id: MenuIds.AppBroker
			},
			{
				category: 'Portal Management',
				id: MenuIds.PortalManagement,
				parentId: MenuIds.AppBroker,
				label: 'Portal Management'
			},
			{
				label: 'Cloud Resource Browser',
				description: 'Manage your resources across clouds.',
				pageTitle: 'Cloud Resource Browser',
				urlMatch: /^\/orgs\/\d+\/cloud(\/|$|\?)/,
				id: MenuIds.CloudResourceBrowser
			},
			{
				label: 'IT Visibility',
				description: 'Discover and analyze your installed enterprise software.',
				helpHref: `${helpDocBaseUrl}/ITVisibility/ITVisibilityGS.htm`,
				pageTitle: 'IT Visibility',
				urlMatch: /^\/orgs\/\d+\/(itv|udi)(\/|$|\?)/,
				id: MenuIds.ITVisibility
			},
			{
				id: MenuIds.UDI,
				parentId: MenuIds.ITVisibility,
				label: 'Discovery & Inventory',
				category: 'Discovery & Inventory',
				helpHref: `${helpDocBaseUrl}/Beacons/GetStartBeacons.htm`,
				icon: IconFxBeacon,
				priority: 200
			},
			{
				label: content.optima,
				description: 'Understand your cloud costs and usage.',
				helpHref: `${helpDocBaseUrl}/Optima/GettingStarted.htm`,
				pageTitle: content.optima,
				urlMatch: /^\/orgs\/\d+\/optima(\/|$|\?)/,
				id: MenuIds.Optima
			},
			{
				label: 'Policies',
				description: 'Automate governance controls across your IT infrastructure.',
				pageTitle: 'Policies',
				urlMatch: /^\/orgs\/\d+\/policy(\/|$|\?)/,
				id: MenuIds.Policies
			},
			{
				label: content.saasManager,
				description: "Manage your organization's SaaS spend.",
				helpHref: `${helpDocBaseUrl}/SaaSManager/GettingStarted.htm`,
				pageTitle: content.saasManager,
				urlMatch: /^\/orgs\/\d+\/saas(\/|$|\?)/,
				id: MenuIds.SaaS
			},
			{
				id: MenuIds.SaaSApps,
				parentId: MenuIds.SaaS,
				icon: IconMdApps,
				category: 'Applications',
				label: 'Applications'
			},
			{
				id: MenuIds.SaaSUsers,
				parentId: MenuIds.SaaS,
				icon: IconMdUser,
				category: 'Users',
				label: 'Users'
			},
			{
				id: MenuIds.SaaSSettings,
				parentId: MenuIds.SaaS,
				icon: IconMdSettings,
				category: 'Settings',
				label: 'Settings'
			},
			{
				label: `${content.unifiedSpend} Analytics`,
				description: 'See your spend across SAM, SaaS, and Cloud.',
				helpHref: `${helpDocBaseUrl}/UnifiedSpendAnalytics/spenddata.htm`,
				pageTitle: `${content.unifiedSpend} Analytics`,
				urlMatch: /^\/orgs\/\d+\/spend(\/|$|\?)/,
				id: MenuIds.UnifiedSpend
			},
			{
				label: 'IT Assets',
				description: 'Manage and optimize your software licenses and costs.',
				pageTitle: 'IT Assets',
				urlMatch: /^\/orgs\/\d+\/slo(\/|$|\?)/,
				id: MenuIds.ItAssets
			}
		]);
	}, [authenticated, orgId]);

	return null;
};
