import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

export const AddRemoveFaveButton = styled.button`
	background: none !important;
	width: unset !important;
	border: none;
	height: ${BaseTheme.space.md};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 12px;
	margin-left: auto;
`;
