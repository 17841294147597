import {
	useOrgId,
	useClearCurrentOrg,
	useCurrentOrg,
	useOrgs
} from '@flexera/shell.orgs';
import { HeaderOrgSwitcher } from '@flexera/ui.component-library';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const OrgPicker = () => {
	const orgs = useOrgs();
	const currentOrg = useCurrentOrg();
	const history = useHistory();
	const clearOrg = useClearCurrentOrg();
	const orgId = useOrgId();

	if (currentOrg.id < 0 || !orgId) {
		return null;
	}

	return (
		<HeaderOrgSwitcher
			id={'header-org-switcher'}
			title={`Organizations`}
			label={`Select an Organization`}
			headingLabel={currentOrg ? currentOrg.name : ''}
			orgs={orgs
				.map((o) => ({
					label: o.name,
					hasDivider: false,
					isHeading: false,
					isSelected: o.id === currentOrg.id,
					onClick: () => {
						if (o.id === currentOrg.id) return;
						clearOrg();
						history.push(`/orgs/${o.id}`);
					}
				}))
				.sort((orgOne, orgTwo) => {
					return orgOne.label.localeCompare(orgTwo.label);
				})}
		/>
	);
};
