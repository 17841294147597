import { ChildRoutes } from '@flexera/lib.router';
import { useMenu } from '@flexera/shell.navigation';
import {
	AsyncMessageStateModule,
	AsyncMessages
} from '@flexera/lib.async-message';
import React, { FC } from 'react';
import { LayoutProvider } from './context/LayoutProvider';
import { AppHeader } from './header/AppHeader';
import { Nav } from './nav/Nav';
import { NavigationStateProvider } from './nav/state/store';
import { PageContainer, Container, HeaderContainer } from './styles/AppLayout';

export const V1Layout: FC = () => {
	const menu = useMenu();

	return (
		<LayoutProvider>
			<NavigationStateProvider>
				<Container>
					{menu.length ? (
						<>
							<HeaderContainer>
								<AppHeader />
							</HeaderContainer>
							<Nav />
						</>
					) : (
						<>
							<HeaderContainer empty />
							<div />
						</>
					)}
					{menu && menu.length > 0 ? (
						<PageContainer>
							<AsyncMessageStateModule>
								<AsyncMessages />
								<ChildRoutes />
							</AsyncMessageStateModule>
						</PageContainer>
					) : (
						<ChildRoutes />
					)}
				</Container>
			</NavigationStateProvider>
		</LayoutProvider>
	);
};
