import React, { FC, useEffect } from 'react';
import { Admin } from '@flexera/admin.bootstrap';
import { AppBroker } from '@flexera/appBroker.bootstrap';
import { useAuthenticated } from '@flexera/auth.base';
import { CloudManagement } from '@flexera/cloud-management.bootstrap';
import { Entitlement } from '@flexera/entitlement.bootstrap';
import {
	useFnmsIframe,
	useShowSnowAtlasLink
} from '@flexera/flexera-one.feature-flags';
import { FNMSCookieRefreshIframe } from '@flexera/fnms.bootstrap';
import { ITVisibility } from '@flexera/itv.bootstrap';
import { Optima } from '@flexera/optima.bootstrap';
import { SaasManager } from '@flexera/saas.bootstrap';
import { Technopedia } from '@flexera/technopedia.bootstrap';
import { SelfService } from '@flexera/self-service.bootstrap';
import { BusinessServices } from '@flexera/business-services.bootstrap';
import { SbomManagement } from '@flexera/sbom-management.bootstrap';
import { Settings } from '@flexera/settings.bootstrap';
import { loadTagManager } from '@flexera/settings.main';
import { loadPendoAnonymous } from '@flexera/lib.pendo';
import { AppLayout } from '@flexera/shell.app-layout';
import { useUpdateGTMData } from '@flexera/shell.google-tag-manager';
import { Udi } from '@flexera/udi.bootstrap';
import { UnifiedSpend } from '@flexera/spend.bootstrap';
import { UnifiedEntitlements } from '@flexera/unified-entitlements.bootstrap';
import { Workspaces } from '@flexera/workspaces.bootstrap';
import { Vendor } from '@flexera/vendor.bootstrap';
import { Contracts } from '@flexera/contracts.bootstrap';
import { PurchasesWrapper } from '@flexera/purchases.bootstrap';
import { InternalAdmin } from '@flexera/internal-admin.bootstrap';
import { Automation } from '@flexera/automation.bootstrap';
import { Odm } from '@flexera/odm.bootstrap';
import { AmChartsVisualizations } from '@flexera/data-visualization.amcharts';
import { AgGridVisualization } from '@flexera/data-visualization.ag-grid';
import { CoreVisualizations } from '@flexera/data-visualization.core';
import { CarouselVisualization } from '@flexera/data-visualization.carousel';
import { Reclamation } from '@flexera/reclamation.bootstrap';
import { VueDemo } from '@flexera/vue.bootstrap';
import { SVR } from '@flexera/svr.bootstrap';
import { UOM } from '@flexera/uom.bootstrap';
import { Platform } from '@flexera/platform.bootstrap';
import { HIF } from '@flexera/hif.bootstrap';
import { Snow } from '@flexera/snow.bootstrap';
import { useUserPreferences } from '@flexera/shell.user-preferences';
import { Nav } from './Nav';
import { useAppRoutes } from './useAppRoutes';

export const InitApp: FC = () => {
	useUpdateGTMData();
	const showFnms = useFnmsIframe();
	useAppRoutes();
	const authenticated = useAuthenticated();
	const {
		getUserPreferences,
		preferences,
		getUserPreference
	} = useUserPreferences();
	const showSnowAtlasLink = useShowSnowAtlasLink();

	async function fetchUserPreferences() {
		await getUserPreferences();
	}

	useEffect(() => {
		if (authenticated) {
			if (localStorage.getItem('auth.starttime') === null) {
				localStorage.setItem('auth.starttime', String(Date.now()));
			}
			if (!preferences) fetchUserPreferences();
			if (preferences) {
				const cookiePref = getUserPreference('cookie_settings');
				if (cookiePref) {
					const { value: acceptedModal } = cookiePref;
					const loadGTM = JSON.parse(acceptedModal as string);
					// Don't try to load tag manager until we know the user is authenticated
					if (loadGTM) {
						loadTagManager();
					} else {
						loadPendoAnonymous();
					}
				}
			}
		}
	}, [authenticated, preferences]);

	return (
		<Nav>
			<AppLayout />
			{showFnms && <FNMSCookieRefreshIframe />}
			<SVR />
			<Automation />
			<SaasManager />
			<BusinessServices />
			<SbomManagement />
			<Settings />
			<Admin />
			<AppBroker />
			<CloudManagement />
			<ITVisibility />
			<Udi />
			<Optima />
			<UnifiedEntitlements />
			<UnifiedSpend />
			<SelfService version={2} />
			<Workspaces />
			<Entitlement />
			<Contracts />
			<PurchasesWrapper />
			<Vendor />
			<InternalAdmin />
			<Odm />
			<AmChartsVisualizations />
			<AgGridVisualization />
			<CoreVisualizations />
			<CarouselVisualization />
			<Reclamation />
			<Technopedia />
			<VueDemo />
			<UOM />
			<Platform />
			<HIF />
			{showSnowAtlasLink ? <Snow /> : <></>}
		</Nav>
	);
};
