import { MenuItem, useMenu } from '@flexera/shell.navigation';
import { IconMdFolder } from '@flexera/ui.component-library';
import { useLocation } from 'react-router-dom';

interface LeftNavItem extends MenuItem {
	items: LeftNavItem[];
}

const toNavItem = (item: MenuItem): LeftNavItem => ({
	label: '- Unknown -',
	icon: item.icon || IconMdFolder,
	...item,
	items: item.children?.length ? item.children.map(toNavItem) : undefined
});

export function useLeftNav() {
	const location = useLocation();
	const menu = useMenu();

	// Find the first item that matches the current location path
	const branch = menu.find(
		(item) =>
			item.urlMatch?.test(location.pathname) ||
			location.pathname.startsWith(item.path.split('?')[0])
	);

	if (!branch) return [];

	// If that item is found and has children, use it as base
	// otherwise use the top level menu
	return branch.children?.length
		? branch.children.map(toNavItem)
		: menu.map(toNavItem);
}
