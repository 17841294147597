import React, { createContext, useReducer } from 'react';
import { InitialState, initialState } from './state';
import { NavigationActions } from './actions';

export const store = createContext(null);
const { Provider } = store;
store.displayName = 'Nav Component';

const homeMenuId = 'apps-flexera-one-home';
const internalAdminId = 'apps.flexera-one/internal-admin';
const workspacesId = 'apps.flexera-one/workspaces';

type Action = { type: string; data: any };

const reducer = (state: InitialState, action: Action) => {
	const { data, type } = action;

	/** List of menu ids that do not contain subnav items */
	const noSubNavIDs = [homeMenuId, workspacesId, internalAdminId];

	/** Check for current nav id against no subnav ids */
	const includesNavId = (navId: string) => {
		return noSubNavIDs.includes(navId);
	};

	switch (type) {
		case NavigationActions.onPrimaryItemHover:
			return {
				...state,
				focusTrap: true,
				isFlyoutOpen: data.children.length > 0,
				currentChildren: data.children,
				activeRouteId: data.id
			};
		case NavigationActions.setCurrentAppAndActiveId:
			return { ...state, currentAppId: data, activeRouteId: data };
		case NavigationActions.closeNavAndFlyout:
			return { ...state, isNavExpanded: false, isFlyoutOpen: false };
		case NavigationActions.expandNavAndUnpinFlyout:
			return { ...state, isNavExpanded: true, isFlyoutPinned: false };
		case NavigationActions.openFlyoutAndSetChildren:
			return {
				...state,
				isFlyoutOpen: !includesNavId(data.id),
				isFlyoutPinned: includesNavId(data.id) ? false : state.isFlyoutPinned,
				currentChildren: data.children,
				activeRouteId: data.id
			};
		case NavigationActions.openSearch:
			return {
				...state,
				isNavExpanded: true,
				isSearchActive: true
			};
		case NavigationActions.unpinFlyout:
			return {
				...state,
				isFlyoutOpen: false,
				isFlyoutPinned: false
			};
		case NavigationActions.resetSearch:
			return {
				...state,
				isSearchActive: false,
				search: ''
			};
		case NavigationActions.setActiveRouteId:
			return { ...state, activeRouteId: data };
		case NavigationActions.setCurrentAppId:
			return { ...state, currentAppId: data };
		case NavigationActions.setCurrentChildren:
			return { ...state, currentChildren: data };
		case NavigationActions.setFilteredChildren:
			return { ...state, filteredChildren: data };
		case NavigationActions.setIsNavExpanded:
			return { ...state, isNavExpanded: data };
		case NavigationActions.setIsFlyoutPinned:
			return { ...state, isFlyoutPinned: data };
		case NavigationActions.setIsFlyoutOpen:
			return { ...state, isFlyoutOpen: data };
		case NavigationActions.setInactiveStyles:
			return { ...state, inactiveStyles: data };
		case NavigationActions.setIsSearchActive:
			return { ...state, isSearchActive: data };
		case NavigationActions.setSearch:
			return { ...state, search: data };
		case NavigationActions.setFocusTrap:
			return { ...state, focusTrap: data };
		case NavigationActions.setFavoriteMenuItems:
			return {
				...state,
				favoriteMenuStore: data.favoriteMenuStore
			};
		case NavigationActions.favoriteIconClicked:
			return { ...state, clickedFavoritedItem: data };
		case NavigationActions.setRecentSearches:
			return {
				...state,
				recentSearches: data.recentSearches
			};
		default:
			return state;
	}
};

export const NavigationStateProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
