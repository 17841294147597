import { Icon, IconMdMenu } from '@flexera/ui.component-library';
import React, { useContext } from 'react';
import { LayoutContext } from '../context/LayoutContext';

export const ExpandIcon = () => {
	const { toggle } = useContext(LayoutContext);
	return (
		<Icon
			src={IconMdMenu}
			mr={'lg'}
			tabIndex={'0'}
			onClick={toggle}
			onEnter={toggle}
			cursor={'pointer'}
		/>
	);
};
