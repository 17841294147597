import React, { useState, useContext, useRef, useEffect } from 'react';
import { flushSync } from 'react-dom';
import { Link } from 'react-router-dom';
import {
	getPillTextFromLabelType,
	NavItem,
	NavItemChild,
	PillInfo
} from '@flexera/shell.navigation';
import { Icon, Pill } from '@flexera/ui.component-library';
import { t } from 'ttag';
import { NavigationContext } from '../navigationContext';
import { ListItem, FlyoutDrawer } from './MenuItem.styled';
import { Placeholder } from '../Placeholder/Placeholder';
import { Submenu } from '../Submenu/Submenu';
import { handleKeyboardAccess } from '../../utilities';
import { PinButton } from '../PinButton/PinButton';
import { Resizer } from '../Resizer/Resizer';

interface MenuItemProps {
	item: NavItem;
	index: number;
	searchWord?: string;
	setFlyoutOpen?: (val) => void;
	handleFlyoutLeave?: () => void;
}

export const MenuItem = ({
	item,
	index,
	searchWord,
	setFlyoutOpen,
	handleFlyoutLeave
}: MenuItemProps) => {
	const {
		isExpanded,
		searchIsActive,
		setSearchIsActive,
		favoritesMenu,
		setPinnedItem,
		isPinned
	} = useContext(NavigationContext);
	const [isResizing, setIsResizing] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [submenuScrollable, setSubmenuScrollable] = useState(false);
	const flyoutRef = useRef();
	const itemRef = useRef();
	const submenuRef = useRef();
	const hasChildren =
		item.children && item.children.filter((c) => !c.hidden).length > 0;
	const isFavorites = item.id && (item.id as string).includes('favorites');
	const spaceAndCharactersRegex = /[^a-zA-Z0-9]/g;
	const cleanSelector =
		item.label && item.label.replace(spaceAndCharactersRegex, '');
	let activationTimer = null;
	let deactivationTimer = null;
	let blurTimer = null;

	useEffect(() => {
		if (submenuRef.current) {
			const isScrollable =
				submenuRef.current.scrollHeight > submenuRef.current.clientHeight;
			setSubmenuScrollable(isScrollable);
		}
	}, [isActive]);

	const handleActivate = () => {
		clearTimeout(deactivationTimer);
		activationTimer = setTimeout(() => {
			if (isPinned) {
				flushSync(() => {
					setPinnedItem(cleanSelector);
				});
				return;
			}
			if (!isActive) {
				flushSync(() => {
					setIsActive(true);
				});
			}
		}, 75);
	};

	const handleDeactivate = (e?) => {
		clearTimeout(activationTimer);
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (isResizing || isPinned) return;
		deactivationTimer = setTimeout(() => {
			flushSync(() => {
				setIsActive(false);
			});
		}, 250);
	};

	const handleBlur = () => {
		blurTimer = setTimeout(() => {
			handleDeactivate();
		});
	};

	const determineClassName = () => {
		let classString;
		if (isActive) {
			classString = `${cleanSelector} active`;
		} else {
			classString = cleanSelector;
		}
		return classString;
	};

	// return conditions
	// do not render items without labels
	if (!item.label) return null;

	// loading items need the animated placeholder
	if (item.label && item.label.includes('loading')) {
		return (
			<ListItem
				isExpanded={isExpanded}
				showActiveSearchStyle={false}
				aria-label={item.label}
			>
				<Placeholder seed={index} />
			</ListItem>
		);
	}

	let labelInfo: PillInfo | undefined;

	if (item.labelType) {
		labelInfo = getPillTextFromLabelType(item.labelType);
	}

	// Home is a link, for example
	if (!searchIsActive && !hasChildren && item.path) {
		return (
			<ListItem
				showActiveSearchStyle={false}
				isExpanded={isExpanded}
				role={'menuitem'}
				id={`${item.id}`}
				className={`${cleanSelector} navlink`}
				data-test-id={item.id}
				aria-labelledby={`${item.id}-link`}
			>
				<Link
					tabIndex={0}
					to={item.path}
					id={`${item.id}-link`}
					aria-haspopup={'false'}
					className={'main-nav-link'}
					aria-label={item.label}
				>
					<span className={'icon-and-label-wrapper full-width'}>
						<Icon src={item.icon} />
						{isExpanded && <span>{item.label}</span>}
						{isExpanded && labelInfo?.text && (
							<Pill
								id={`${item.id}`}
								variant={labelInfo.pillVariant}
							>{`${labelInfo.text}`}</Pill>
						)}
					</span>
				</Link>
			</ListItem>
		);
	}

	// loaded items have complete presentation
	if (searchIsActive) {
		return (
			<ListItem
				showActiveSearchStyle={searchIsActive}
				tabIndex={0}
				role={'menuitem'}
				aria-haspopup={'false'}
				aria-label={item.label}
				isExpanded={isExpanded}
				id={item.id as string}
				data-test-id={item.id}
			>
				<span className={'icon-and-label-wrapper'}>
					<Icon src={item.icon} />
					{isExpanded && <span>{item.label}</span>}
				</span>
				{item.children && item.children.length > 0 && (
					<Submenu
						childItems={item.children}
						searchIsActive={searchIsActive}
						setSearchIsActive={setSearchIsActive}
						searchWord={searchWord}
					/>
				)}
			</ListItem>
		);
	}

	/**  when search isn't active we need a flyout and special events on hover/mouseEnter/keyDown
	 *
	 */
	return (
		<ListItem
			tabIndex={0}
			role={'menuitem'}
			id={item.id as string}
			aria-haspopup={'true'}
			className={determineClassName()}
			data-test-id={item.id}
			isExpanded={isExpanded}
			isPinned={isPinned}
			showActiveSearchStyle={searchIsActive}
			onFocus={() => clearTimeout(blurTimer)}
			onBlur={handleBlur}
			onKeyUp={(e) => {
				e.preventDefault();
				if (!e.currentTarget.classList.contains('pin-button')) {
					handleKeyboardAccess(e, handleActivate);
				}
			}}
			onMouseEnter={() => handleActivate()}
			onMouseLeave={(e) => handleDeactivate(e)}
			ref={itemRef}
		>
			<span
				role={isExpanded ? 'contentinfo' : 'img'}
				aria-label={item.label}
				className={'icon-and-label-wrapper'}
			>
				<Icon src={item.icon} />
				{isExpanded && <span>{item.label}</span>}
				{isExpanded && labelInfo?.text && (
					<Pill
						id={`${item.id}`}
						variant={labelInfo.pillVariant}
					>{t`${labelInfo.text}`}</Pill>
				)}
			</span>
			{(hasChildren || isFavorites) && (
				<FlyoutDrawer
					className={'flyout-drawer'}
					ref={flyoutRef}
					onMouseLeave={() => handleFlyoutLeave()}
					isExpanded={isExpanded}
				>
					<Submenu
						childItems={
							isFavorites ? (favoritesMenu as NavItemChild[]) : item.children
						}
						searchIsActive={searchIsActive}
						setIsActive={setIsActive}
						setFlyoutOpen={setFlyoutOpen}
						submenuRef={submenuRef}
					/>
					<Resizer
						setIsResizing={setIsResizing}
						parentRef={flyoutRef}
						deactivateFlyout={() => handleDeactivate()}
					/>
					<PinButton
						parentSelector={cleanSelector}
						parentRef={itemRef}
						drawerOpen={isActive}
						scrollable={submenuScrollable}
					/>
				</FlyoutDrawer>
			)}
		</ListItem>
	);
};
