import { Admin } from '@flexera/admin.bootstrap';
import { AppBroker } from '@flexera/appBroker.bootstrap';
import { useFnmsIframe } from '@flexera/flexera-one.feature-flags';
import { FNMSCookieRefreshIframe } from '@flexera/fnms.bootstrap';
import { ITVisibility } from '@flexera/itv.bootstrap';
import { Optima } from '@flexera/optima.bootstrap';
import { SaasManager } from '@flexera/saas.bootstrap';
import { Settings } from '@flexera/settings.bootstrap';
import { V1Layout } from '@flexera/shell.v1-layout';
import { Udi } from '@flexera/udi.bootstrap';
import { UnifiedSpend } from '@flexera/spend.bootstrap';
import { SelfService } from '@flexera/self-service.bootstrap';
import React from 'react';
import { MenuIds } from './MenuIds';
import { useV1Routes } from './useV1Routes';
import { Nav } from './Nav';

export const InitV1App = () => {
	useV1Routes();
	const showFnms = useFnmsIframe();

	return (
		<Nav>
			{showFnms && <FNMSCookieRefreshIframe rootNav={MenuIds.ItAssets} />}
			<SaasManager />
			<Settings />
			<AppBroker />
			<Admin />
			<ITVisibility />
			<Udi />
			<Optima />
			<UnifiedSpend />
			<SelfService version={1} />
			<V1Layout />
		</Nav>
	);
};
