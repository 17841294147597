import { useRoutes } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { OrgsRoot } from '@flexera/shell.org-landing';

export function useV1Routes() {
	const { addRoutes } = useRoutes();

	useEffect(() => {
		return addRoutes([
			{
				id: 'orgs-root',
				path: '',
				parentId: OrgsRouteId,
				component: OrgsRoot
			}
		]);
	}, []);
}
