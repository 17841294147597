import { useMenu } from '@flexera/shell.navigation';
import {
	H3,
	P,
	HeaderAppSwitcher,
	HeaderAppSwitcherItem,
	Button
} from '@flexera/ui.component-library';
import { useOrgId } from '@flexera/shell.orgs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CustomAppSwitcherItem } from './Styled';

export const AppSwitcher = () => {
	const menuItems = useMenu();
	const location = useLocation();
	const orgId = useOrgId();

	// Calculate how many child items there are under the top level
	const childCount = menuItems.reduce(
		(count, item) => (item.children?.length || 0) + count,
		0
	);

	// If no children or org, then hide the app switcher because it's not switching anything
	if (childCount === 0 || !orgId) return null;

	return (
		<HeaderAppSwitcher id={'app-switcher-popover'}>
			{menuItems.map((i) =>
				i.onClick ? (
					<CustomAppSwitcherItem>
						<Button
							type={'button'}
							id={i.id.toString()}
							title={i.label}
							onClick={i.onClick}
						>
							<H3>{i.label}</H3>
							<P>{i.description}</P>
						</Button>
					</CustomAppSwitcherItem>
				) : (
					<HeaderAppSwitcherItem
						key={i.id}
						id={i.id.toString()}
						mainText={i.label}
						subText={i.description}
						to={i.path}
						isActive={
							i.urlMatch?.test(location.pathname) ||
							location.pathname.startsWith(i.path.split('?')[0])
						}
					/>
				)
			)}
		</HeaderAppSwitcher>
	);
};
