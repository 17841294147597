import styled from 'styled-components';

interface PageContainerProps {
	noPadding?: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
	padding: ${(props) => (props.noPadding ? 0 : `40px 0`)};
	position: relative;
	overflow-y: auto;
	& .gov-styles-wrapper {
		margin-top: -40px;
		margin-bottom: -40px;
		height: calc(100% + 80px);
	}
`;

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: --ms-grid;
	--ms-grid-column: auto 1fr;
	--ms-grid-row: 1fr;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr;
	overflow: hidden;
	background-color: rgb(248, 248, 248);
`;

export const HeaderContainer = styled.div<{ empty?: boolean }>`
	grid-column: span 2;
	z-index: 999;
	height: ${(p) => (p.empty ? '0' : '70px')};
`;
